<template>
  <input :type="type || 'text'" class="oa-input" :placeholder="placeholder" :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)" ref="inputElement" />
</template>

<script>
export default {
  name: "Input",
  props: {
    placeholder: String,
    type: String,
    modelValue: String
  },
  methods: {
    focusInput() {
        this.$refs.inputElement.focus();
    }
  }
};
</script>

<style>
.oa-input {
  font-family: "Inter";
  position: relative;
  box-sizing: border-box;
  padding: 16px 16px 16px 48px;
  border-radius: 12px;
  width: 100%;
  border: none;
  box-shadow: 0 0 0 2px var(----color-gray-300);
  color: var(--color-gray-900);
  font-size: 14px;
  font-weight: 500;
  isolation: isolate;
}

.oa-input::placeholder {
  margin-bottom: 0;
  color: var(--color-pale);
  font-size: 14px;
  font-weight: 500;
}

.oa-input:active,
.oa-input:focus-visible,
.oa-input:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--color-brand-medium);
  background-image: url("https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/openaccess/magnifier-dark.svg");
}

.oa-input--search {
  background-image: url("https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/openaccess/magnifier-light.svg");
  background-repeat: no-repeat;
  background-position: left 16px center;
  background-size: 20px;
}
</style>