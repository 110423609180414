<template>
	<div class="oa-wrapper">
		<div class="oa-modal" v-if="!missingSDS" :data-hide="hideModal">
			<div class="oa-modal__headline oa-modal__headline--grid">
				<span class="oa-modal__artno">{{ data.artNo }}</span>
				<p class="oa-modal__name">{{ data.name }}</p>
				<span class="oa-modal__close" @click="$emit('close')" @keydown.esc="closeModal" />
			</div>
			<ul class="oa-modal__list">
				<li class="oa-modal__list__item" :class="{'oa-modal__list__item--activities': productHasActivities}" v-if="data.isProduct && productHasActivities">
					<a
						href="#"
						class="oa-modal__list__link"
						@click.prevent="openActivitiesModal"
						>{{ currentLanguage.activityBasedSafetySheet }}</a>

				</li>
				<li class="oa-modal__list__item" v-if="data.isProduct && data.hasSds">
					<a
						:href="productSdsUrl"
						class="oa-modal__list__link"
						@click.prevent="downloadItem(productSdsUrl, data, 'sds')"
						>{{ currentLanguage.safetyDataSheet }}</a
					>
				</li>
				<li class="oa-modal__list__item" v-if="data.isActivity">
					<a
						:href="activitySafetySheetUrl"
						class="oa-modal__list__link"
						@click.prevent="
							downloadItem(activitySafetySheetUrl, data, 'activity')
						"
						>{{ currentLanguage.activityBasedSafetySheet }}</a
					>
				</li>
				<li class="oa-modal__list__item" v-if="data.isProduct">
					<a
						:href="productSafetySheetUrl"
						class="oa-modal__list__link"
						@click.prevent="downloadItem(productSafetySheetUrl, data, 'ss')"
						>{{ currentLanguage.productBasedSafetySheet }}</a
					>
				</li>
				<li class="oa-modal__list__item" v-if="data.isWpi">
					<a
						:href="productWpiUrl"
						class="oa-modal__list__link"
						target="_blank"
						@click.prevent="downloadItem(productWpiUrl, data)"
						>{{ currentLanguage.wpiFile }}</a
					>
				</li>
				<li class="oa-modal__list__item" v-if="data.isActivity">
					<a
						href="#"
						@click.prevent="openProductsModal"
						class="oa-modal__list__link oa-modal__list__link--products"
						>See all products<span class="oa-modal__list__numeration">({{ numberOfProductsInActivity }})</span></a>

				</li>
			</ul>
		</div>
		<transition name="activities">
			<ModalElement ref="activityModal" class="oa-modal--activities" :class="{'oa-modal--visible': productActivityData.length <= 3}" v-show="hideModal" @closeActivitiesModal="closeActivitiesModal">
				<Loader v-if="!activitiesLoaded" />
				<li v-else class="oa-modal__list__item" v-for="(activity, index) in productActivityData" :key="activity.name">
					<button @click.prevent="downloadProductActivity(activity)" class="oa-modal__list__wrapper">
						<div class="oa-modal__list__link"
						>{{activity.name}}</div>
						<span class="oa-modal__list__location">{{activity.locationName}}
							<DepartmentPath
								:ref="'activity-' + index"
								:path="activity.locationPath"
								:data-tooltip-position="index === productActivityData.length - 1 ? 'top' : position"
								@mouseenter="setDynamicPosition('activity-' + index)"
							/>
						</span>
					</button>
				</li>
			</ModalElement>
		</transition>
		<div class="oa-modal__area" @click="$emit('close')" />
		<transition name="modal">
			<ConfirmBox v-if="missingSDS" @continue="confirmBoxContinue()" />
		</transition>
	</div>
</template>

<script>
	import axios from "../../axios";
	import useTranslations from "@modules/translations.js";
	import { adjustContextPosition } from "@utils/adjustContextPosition.js"
	import ConfirmBox from "@components/common/ConfirmBox.vue";
	import DepartmentPath from "@components/DepartmentPath.vue";
	import ModalElement from "@components/common/ModalElement.vue";
	import Loader from "@components/common/Loader.vue";

	export default {
		name: "Properties",
		components: {
			ConfirmBox,
			DepartmentPath,
			ModalElement,
			Loader
		},
		mounted() {
			window.addEventListener('keydown', this.closeModal);
			if(this.data.isProduct)
				this.checkProductActivities();
			if(this.data.isActivity)
				this.getNumberOfProductsInActivity();
		},
		beforeUnmount() {
			window.removeEventListener('keydown', this.closeModal);
		},
		setup() {
			const { currentLanguage, changeLanguage } = useTranslations();
			return { currentLanguage };
		},
		computed: {
			productHasActivities() {
				return this.hasActivities;
			},
		},
		props: ["data", "token", "department"],
		data() {
			return {
				isSafariBrowser: /^((?!chrome|android).)*safari/i.test(
					navigator.userAgent
				),
				activitySafetySheetUrl:
					process.env.VUE_APP_URL +
					`api/Activities/SafetySheet/${this.data.riskAssessmentID}`,
				productSafetySheetUrl:
					`api/Products/SafetySheet/${this.data.id}/${this.data.idMdbID}`,
				productSdsUrl:
					"api/Products/SafetyDataSheet/" +
					this.data.id +
					"/" +
					this.data.idMdbID,
				productWpiUrl: "api/Products/WPI/" + this.data.fileName,
				missingSDS: false,
				hideModal: false,
				activitiesLoaded: false,
				productActivityData: [],
				position: 'bottom',
				hasActivities: false,
				activityProductsList: [],
				numberOfProductsInActivity: "0"
			};
		},
		methods: {
			downloadItem(url, data, type) {
				const config = {
					headers: {
						"open-access-token": this.token,
					},
				};

				axios
					.get(url, config)
					.then((response) => {
						var bytes = this.base64ToArrayBuffer(response.data);

						const blob = new Blob([bytes], {
							type: "application/pdf",
						});
						const link = document.createElement("a");
						link.href = URL.createObjectURL(blob);
						var documentName =
							data.name?.length > 250 ? data.name?.substring(0, 250) : data?.name;
						link.download =
							type === "activity"
								? `${this.department}-${documentName}`.replace('.', '-') + '-ss.pdf'
								: type === "sds"
								? `${this.department}-${documentName}-${data.artNo}-${type}`.replace('.', '-') + '.pdf'
								: `${this.department}-${documentName}`.replace('.', '-') + '.pdf';
						link.click();

						if (!this.isBrowserSafari) {
							URL.revokeObjectURL(link.href);
						}
					})
					.catch((error) => {
						console.error(error);

						if (type == "sds") {
							this.missingSDS = true;
						}
					});
			},
			base64ToArrayBuffer: function (base64) {
				var binaryString = window.atob(base64);
				var binaryLen = binaryString.length;
				var bytes = new Uint8Array(binaryLen);
				for (var i = 0; i < binaryLen; i++) {
					var ascii = binaryString.charCodeAt(i);
					bytes[i] = ascii;
				}
				return bytes;
			},
			downloadProductActivity:function(activity){
				this.downloadItem(
					process.env.VUE_APP_URL +
					`api/Activities/SafetySheet/${activity.riskAssessmentID}`, activity, 'activity');
			},
			confirmBoxContinue() {
				this.missingSDS = false;
			},
			closeModal(e) {
				if (e.key === 'Escape' || e.key === 'Esc') {
					this.$emit('close');
				}
			},
			checkProductActivities(){
				const config = {
					headers: {
						"open-access-token": this.token,
					},
				};

				axios
					.get(`api/Product/HasActivities/${this.data.id}/${this.data.idMdbID}`, config)
					.then((response) => {
						this.hasActivities = response.data;
					})
					.catch((error) => {
						console.error(error);
					});
			},
			openActivitiesModal() {
				this.hideModal = true;

				const config = {
					headers: {
						"open-access-token": this.token,
					},
				};
				axios
				.get(`/api/Product/ActivitySafetySheets/${this.data.id}/${this.data.idMdbID}`, config)
				.then((response) => {
					if (response.data) {
						this.productActivityData = [
							...response.data
						];
						this.activitiesLoaded = true;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
			},
			getNumberOfProductsInActivity() {
				const config = {
					headers: {
						"open-access-token": this.token,
					},
				};
				axios
				.get(`/api/Activities/NumberOfProducts/${this.data.riskAssessmentID}`, config)
				.then((response) => {
					if (response.data) {
						this.numberOfProductsInActivity = response.data;	
					}
					else
						return "0";	
				})
				.catch(function (error) {
					console.log(error);
				});
			},
			openProductsModal() {
				const config = {
					headers: {
						"open-access-token": this.token,
					},
				};
				axios
				.get(`/api/Activities/ActivityProducts/${this.data.riskAssessmentID}`, config)
				.then((response) => {
					if (response.data) {
						this.activityProductsList = [
							...response.data.map((f) => ({ ...f, isProduct: true })),
						];
						this.$emit('activityProductList', this.activityProductsList);
						this.$emit('openPlainSearch');
					}
				})
				.catch(function (error) {
					console.log(error);
				});
			},
			closeActivitiesModal() {
				this.hideModal = false;
			},
			adjustContextPosition,
			setDynamicPosition(refName) {
				const modalComponent = this.$refs.activityModal; // Get the modal Vue component
				const elementComponent = this.$refs[refName]; // Get the DepartmentPath Vue component

				// Adjust position using the Vue component instances
				this.position = adjustContextPosition(elementComponent, this.position, 80, modalComponent);
			},
		},
	};
</script>

<style>
	.oa-wrapper {
		content: "";
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100dvh;
		z-index: 11;
	}

	.oa-modal {
		position: absolute;
		bottom: 0;
		left: 50%;
		translate: -50% 0;
		width: 100%;
		max-width: 1054px;
		padding: 0;
		background-color: var(--color-white);
		border-top-left-radius: 16px;
		border-top-right-radius: 16px;
		z-index: 2;
		border: none;

		/* ANIMATION */
		-webkit-animation: slide-top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
		animation: slide-top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	}

	.oa-modal[data-hide='true'] {
		opacity: 0;
		transition: opacity 0.2s ease-out;
	}

	.oa-modal[data-hide='false'] {
		opacity: 1;
		transition: opacity 0.2s ease-out;
	}

	.oa-modal--activities .oa-modal__list {
		max-height: 270px;
		min-height: 108px;
		overflow: auto;
		scrollbar-width: none;
		-ms-overflow-style: none;
	}

	.oa-modal--activities .oa-modal__list::-webkit-scrollbar {
		display: none;
	}

	.oa-modal--visible .oa-modal__list {
		overflow: visible;
	}

	.oa-modal__area {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(36, 98, 130, 0.35);
	}

	.oa-modal__headline {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 16px 21px 16px 16px;
		border-bottom: 1px solid var(--color-gray-200);
	}

	.oa-modal__headline--grid {
		display: grid;
		grid-template-columns: auto min-content;
		grid-template-rows: auto auto;
		grid-template-areas:
		"artno close"
		"name close";
	}

	.oa-modal__artno {
		grid-area: artno;
		font-size: 14px;
		line-height: 20px;
		color: var(--color-gray-500);
	}

	.oa-modal__name {
		grid-area: name;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: var(--color-gray-900);
	}

	.oa-modal__close {
		grid-area: close;
		width: 24px;
		min-width: 24px;
		height: 24px;
		line-height: 24px;
		margin-left: auto;
		background-image: url(https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/openaccess/close-plain.svg);
		background-repeat: no-repeat;
		background-size: 14px;
		cursor: pointer;
		background-position: center;
	}

	.oa-modal__list {
		display: flex;
		flex-direction: column;
		position: relative;
		padding: 16px;
		gap: 12px;
		background-color: var(--color-gray-100);
	}

	.oa-modal__list__wrapper {
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
		gap: 4px;
		width: 100%;
		padding-right: 32px;
		border: 0;
		background: none;
		cursor: pointer;
	}

	.oa-modal__list__item {
		display: inline-flex;
		position: relative;
		gap: 12px;
		padding: 16px;
		font-weight: 600;
		box-shadow: 0px 0px 1px 0px rgba(75, 85, 99, 0.04), 0px 0px 12px 0px rgba(107, 114, 128, 0.04);
		border-radius: 16px;
		background-image: url("https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/openaccess/download.svg");
		background-size: 21px;
		background-repeat: no-repeat;
		background-position: right 17.5px center;
		background-color: var(--color-white);
	}

	.oa-modal__list__item:has(.oa-modal__list__link--products) {
		background-image: url("https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/cdn-assets/open-access/chevron-right.svg");
	}

	.oa-modal__list__item:has(.oa-modal__list__link--products):before {
		background-image: url('https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/openaccess/flask.svg');
	}

	.oa-modal__list__item:before {
		content: "";
		display: block;
		width: 40px;
		height: 40px;
		background-image: url('https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/cdn-assets/open-access/sds.svg');
		border-radius: 12px;
		background-color: var(--color-brand-light);
		background-repeat: no-repeat;
		background-position: center;
	}

	.oa-modal__list__item--activities {
		background-image: url("https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/cdn-assets/open-access/chevron-right.svg");
	}

	.oa-department-path[data-tooltip-position="top"] .oa-department-path__icon:before {
		transform: rotate(180deg);
		translate: -50% -13px;
	}

	.oa-department-path[data-tooltip-position="top"] .oa-department-path__location {
		translate: 0 calc(-100% - 25px);
	}

	a.oa-modal__list__link {
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
	}

	a.oa-modal__list__link--products {
		flex-direction: row;
		justify-content: initial;
		align-items: center;
		gap: 4px;
	}

	a.oa-modal__list__link--products .oa-modal__list__numeration {
		color: var(--color-gray-500);
	}

	.oa-modal__list__link {
		width: 100%;
		text-align: left;
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
	}

	.oa-modal__list__location {
		display: inline-flex;
		align-items: center;
		color: var(--color-gray-500);
		font-size: 12px;
		line-height: 16px;
	}

	.oa-modal__list__location .oa-department-path {
		color: var(--color-white);
	}

	.oa-modal__list__location .oa-department-path__icon {
		background-image: url("https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/cdn-assets/open-access/info-gray.svg");
	}

	.oa-modal__list__link,
	.oa-modal__list__link:visited,
	.oa-modal__list__link:active {
		color: var(--color-gray-900);
	}

	.activities-enter-active, .activities-leave-active {
		transition: transform 0.3s ease-out;
	}

	.activities-enter, .activities-leave-to /* .modal-slide-leave-active in <2.1.8 */ {
		transform: translateY(50%);
	}
</style>
