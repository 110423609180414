<template>
    <div
        class="oa-results"
    >
        <img :src="img" alt="icon" />
        <h4 class="oa-results__title">{{ title }}</h4>
        <p class="oa-results__text">
            {{ text }}
        </p>
    </div>
</template>
<style>
.oa-results {
    width: 215px;
    margin: 80px auto 0;
    text-align: center;
}

.oa-results__title {
    color: var(--color-gray-900);
    font-weight: 900;
    font-size: 16px;
    line-height: 24px;
    margin: 22px 0 0;
}

.oa-results__text {
    margin-top: 8px;
    color: var(--color-gray-700);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
</style>
<script>
    export default {
        name: "ResultsInfo",
        props: ['img', 'title', 'text']
    };
</script>
