<template>
    <div class="oa-landing-screen">
        <div class="oa-landing-screen__wrapper">
            <img @click="$emit('hideLandingScreen', true)" v-if="secondState" class="oa-landing-screen__close"
                src="https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/openaccess/arrow-left.svg" />
            <CompanyBrand landing="true" :customerName="customerName" />
            <h2 class="oa-landing-screen__headline">{{ currentLanguage.welcomeMessage }}</h2>
            <p class="oa-landing-screen__desc">{{ currentLanguage.aboutApp.replace('{0}', customerName) }}</p>
            <ul class="oa-landing-screen__list">
                <li class="oa-landing-screen__list__item">
                    <img class="oa-landing-screen__list__img"
                        src="https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/openaccess/magnifier-blue.svg"
                        alt="activity" />
                    <h3 class="oa-landing-screen__list__caption">{{ currentLanguage.search }}</h3>
                    <p class="oa-landing-screen__list__text">{{ currentLanguage.searchDesc }}</p>
                </li>
                <li class="oa-landing-screen__list__item">
                    <img class="oa-landing-screen__list__img"
                        src="https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/openaccess/flask.svg"
                        alt="activity" />
                    <h3 class="oa-landing-screen__list__caption">{{ currentLanguage.products }}</h3>
                    <p class="oa-landing-screen__list__text">{{ currentLanguage.productsDesc }}</p>
                </li>
                <li class="oa-landing-screen__list__item">
                    <img class="oa-landing-screen__list__img"
                        src="https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/openaccess/activity-blue.svg"
                        alt="activity" />
                    <h3 class="oa-landing-screen__list__caption">{{ currentLanguage.activities }}</h3>
                    <p class="oa-landing-screen__list__text">{{ currentLanguage.activitiesDesc }}</p>
                </li>
            </ul>
            <p class="oa-landing-screen__contact">{{ currentLanguage.contactMessage }}
                <a class="oa-landing-screen__contact__email" :href="supportEmailString">{{ supportEmail }}</a>.
            </p>
        </div>
        <div class="oa-landing-screen__button" v-if="!secondState">
            <Button @click="$emit('closeLandingScreen', true)" class="oa-button--primary">{{ currentLanguage.continue
            }}</Button>
        </div>
    </div>
</template>
<style>
.oa-landing-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    max-height: 100%;
    min-height: 100%;
    background-image: url("https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/openaccess/bg-login.svg");
    background-attachment: fixed;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: var(--color-brand-dark);
    opacity: 0.6;
    transform: translateX(100%);
    transition: all 0.25s ease-in-out;
    filter: blur(1px);
}


.oa-landing-screen--show {
    transform: translateX(0%);
    opacity: 1;
    transition: all 0.25s ease-in-out;
    filter: blur(0px);
    overflow-y: hidden;
}

.oa-landing-screen__wrapper {
    display: flex;
    flex-direction: column;
    padding: 24px;
    overflow-y: auto;
}

.oa-landing-screen__close {
    width: 20px;
    height: 15px;
    margin-bottom: 30px;
    cursor: pointer;
}

.oa-landing-screen__headline {
    color: var(--color-white);
    font-size: 24px;
    font-weight: 700;
    margin-top: 32px;
}

.oa-landing-screen__desc {
    color: var(--color-brand-secondary);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 40px;
}

.oa-landing-screen__button {
    display: flex;
    justify-content: center;
    padding: 24px;
    background-color: var(--color-white);
    margin-top: auto;
    align-self: normal;
}

.oa-landing-screen__button .oa-button {
    width: 100%;
    padding-top: 14px;
    padding-bottom: 14px;
}

@media screen and (min-width: 769px) {
    .oa-landing-screen__button .oa-button {
        max-width: 320px;
    }
}

.oa-landing-screen__list {
    margin-bottom: 40px;
}

.oa-landing-screen__list__item {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas:
        "image caption"
        "image text";
    grid-column-gap: 16px;
    margin-bottom: 24px;
}

.oa-landing-screen__list__item:last-child {
    margin-bottom: 0;
}

.oa-landing-screen__list__img {
    grid-area: image;
    padding: 9px;
    background: var(--color-brand-light);
    border-radius: 12px;
    min-width: 40px;
    max-height: 40px;
}

.oa-landing-screen__list__caption {
    grid-area: caption;
    color: var(--color-white);
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
}

.oa-landing-screen__list__text {
    grid-area: text;
    color: var(--color-brand-secondary);
}

.oa-landing-screen__contact {
    color: var(--color-brand-secondary);
}

.oa-landing-screen__contact__email {
    color: var(--color-brand-secondary);
}
</style>
<script>
import Button from "@components/common/Button.vue";
import CompanyBrand from "./CompanyBrand.vue";

// Include translations
import useTranslations from "@modules/translations.js";

export default {
    name: "LandingScreen",
    emits: ['closeLandingScreen', 'hideLandingScreen'],
    setup() {
        const { currentLanguage } = useTranslations();
        return { currentLanguage };
    },
    components: {
        Button,
        CompanyBrand,
    },
    data() {
        return {
            secondState: false,
            supportEmail: "",
            supportEmailString: ""
        }
    },
    props: ["customerName"],
    methods: {
        setLandingScreenState() {
            const hasShownLandingScreen = localStorage.getItem('hasShownLandingScreen');
            if (hasShownLandingScreen === 'false') {
                this.secondState = true;
            }
            this.supportEmail = process.env.VUE_APP_SUPPORT_EMAIL,
                this.supportEmailString = "mailto:" + this.supportEmail
        }
    },
    mounted() {
        this.setLandingScreenState();
    },
    updated() {
        this.setLandingScreenState();
    }
};
</script>
