<template>
  <div class="oa-tabs"
    :class="{ 'oa-tabs--slide-two': activeTabIndex === 1, 'oa-tabs--slide-three': activeTabIndex === 2, 'oa-tabs--slide-four': activeTabIndex === 3, 'oa-tabs--single-tab': tabs.length === 1, 'oa-tabs--two-tabs': tabs.length === 2, 'oa-tabs--three-tabs': tabs.length === 3, 'oa-tabs--four-tabs': tabs.length === 4 }">
    <Button @click="tabClicked(ind)" :class="{ 'oa-button--active': activeTabIndex === ind }" v-for="(tab, ind) in tabs"
      :key="ind"><span class="oa-button__type">{{ tab.name }}</span><span class="oa-button__number">{{ !!tab.numberOfItems
        ? tab.numberOfItems : '0' }}</span>
    </Button>
  </div>
  <slot></slot>
</template>

<script>
import Button from "@components/common/Button.vue";

export default {
  name: "Tabs",
  props: ["tabs"],
  emits: ["switchTab"],
  components: {
    Button,
  },
  data() {
    return {
      activeTabIndex: 0
    }
  },
  methods: {
    tabClicked(index) {
      this.activeTabIndex = index;
      this.$emit("switchTab", this.tabs[index].name, index);
    },
  },
};
</script>

<style>
.oa-tabs {
  display: flex;
  position: relative;
  overflow-y: auto;
  min-height: 56px;
  background-color: var(--color-white);
  padding: 8px;
  border-radius: 16px;
  margin-top: 16px;
  margin-bottom: 12px;
}

.oa-tabs:before {
  content: "";
  width: 50%;
  height: 40px;
  position: absolute;
  top: 8px;
  left: 8px;
  border-radius: 8px;
  background-color: var(--color-brand-primary);
  -webkit-transition: left 0.25s ease-out;
  transition: left 0.25s ease-out;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .oa-tabs:before {
    display: none;
  }

  .oa-tabs .oa-button--active:before {
    content: "";
    width: 100%;
    height: 40px;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 8px;
    background-color: var(--color-brand-primary);
    -webkit-transition: background-color 0.25s ease-out;
    transition: background-color 0.25s ease-out;
  }
}

.oa-tabs.oa-tabs--slide-activities:before {
  left: calc(50% - 8px);
  -webkit-transition: left 0.25s ease-out;
  transition: left 0.25s ease-out;
}

.oa-tabs.oa-tabs--single-tab:before {
  width: calc(100% - 16px);
}

.oa-tabs.oa-tabs--three-tabs:before {
  width: 33%;
}

.oa-tabs.oa-tabs--four-tabs:before {
  width: 25%;
}

.oa-tabs.oa-tabs--two-tabs.oa-tabs--slide-two:before {
  left: calc(50% - 4px);
  -webkit-transition: left 0.25s ease-out;
  transition: left 0.25s ease-out;
}

.oa-tabs.oa-tabs--three-tabs.oa-tabs--slide-two:before {
  left: 33.3333%;
  -webkit-transition: left 0.25s ease-out;
  transition: left 0.25s ease-out;
}


.oa-tabs.oa-tabs--three-tabs.oa-tabs--slide-three:before {
  left: calc(66.6666% - 4px);
  -webkit-transition: left 0.25s ease-out;
  transition: left 0.25s ease-out;
}

.oa-tabs.oa-tabs--four-tabs.oa-tabs--slide-two:before {
  left: 25%;
  -webkit-transition: left 0.25s ease-out;
  transition: left 0.25s ease-out;
}

.oa-tabs.oa-tabs--four-tabs.oa-tabs--slide-three:before {
  left: calc(50% - 4px);
  -webkit-transition: left 0.25s ease-out;
  transition: left 0.25s ease-out;
}

.oa-tabs.oa-tabs--four-tabs.oa-tabs--slide-four:before {
  left: calc(75% - 4px);
  -webkit-transition: left 0.25s ease-out;
  transition: left 0.25s ease-out;
}
</style>
