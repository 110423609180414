<template>
    <div class="oa-loader" />
</template>
<style>
    .oa-loader {
        position: absolute;
        left: 50%;
        top: 50%;
        translate: -50% -50%;
        width: 24px;
        height: 24px;
        background-image: url("https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/openaccess/loader.svg");
        background-repeat: no-repeat;
        animation: spin 2s linear infinite;
    }

    /* Safari */
    @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
</style>
<script>
    export default {
        name: "Loader"
    };
</script>
