
import { use } from '../node_modules/vue/dist/vue.global';
import { createApp } from 'vue';
import * as VueRouter from 'vue-router';
import App from './App.vue';
import MainPage from '@pages/MainPage';
import NotFoundPage from '@pages/NotFoundPage';
import i18n from '@plugins/i18n'


const routes = [
    {
        path: '/:token',
        name: 'home',
        component: MainPage,
        props: true
    },
    {
        path: '/unavailable',
        name: 'unavailable',
        component: NotFoundPage,
        props: true
    }
];

const router = VueRouter.createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: VueRouter.createWebHashHistory(),
    routes, // short for `routes: routes`
});

var app = createApp(App).use(router);
i18n(app);

router.isReady().then(() => {
    app.mount('#app')
})

export default router;
