<template>
  <button class="oa-icon-button" />
</template>
<style>
.oa-icon-button {
  width: 22px;
  height: 22px;
  background-image: url("https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/openaccess/magnifier.svg");
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.oa-icon-button--close {
  background-image: url("https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/openaccess/close-circle.svg");
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.oa-icon-button--back {
  position: absolute;
  background-image: url("https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/openaccess/arrow-left.svg");
  background-size: 19px 14px;
  background-position: center;
  cursor: pointer;
}
</style>
<script>
export default {
  name: "IconButton",
};
</script>
