<template>
  <NotFound />
</template>
<script>
  import NotFound from "@components/NotFound.vue";
  export default {
    name: "NotFoundPage",
    components: {
      NotFound,
    },
  };
</script>