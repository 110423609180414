<template>
  <span class="oa-icon" />
</template>
<style>
.oa-icon {
  width: 48px;
  min-width: 48px;
  height: 48px;
  padding: 13px;
  margin-right: 16px;
  border-radius: 12px;
  background-color: var(--color-brand-light);
  background-image: url("https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/cdn-assets/open-access/activity-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
}

.oa-icon.oa-icon--flask {
  background-image: url("https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/openaccess/flask.svg");
}

.oa-icon.oa-icon--wpi {
  background-image: url("https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/cdn-assets/open-access/wpi-icon.svg");
}
</style>
<script>
export default {
  name: "Icon",
};
</script>
