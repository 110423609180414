<template>
    <svg width="264" height="48" viewBox="0 0 264 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M42.2135 8.99676L35.9385 4.36332V4.39004C33.5505 2.63329 30.8397 1.36415 27.961 0.655101C25.0824 -0.0539443 22.0922 -0.188999 19.1613 0.257652C16.2304 0.704303 13.4162 1.72391 10.8795 3.25823C8.34274 4.79255 6.13317 6.81153 4.37697 9.19984C2.39507 11.8999 1.03874 15.007 0.406241 18.2959C-0.226256 21.5849 -0.119069 24.9733 0.720033 28.2157C1.55914 31.4581 3.10914 34.4733 5.25772 37.0427C7.4063 39.6121 10.0996 41.6715 13.1426 43.0715C13.2035 42.7646 13.3155 42.4701 13.474 42.2004C13.7727 41.6914 14.2343 41.2981 14.7842 41.0838C15.3341 40.8695 15.9401 40.8468 16.5045 41.0193C18.3065 41.4985 20.188 41.6004 22.0311 41.3186C21.7529 40.8592 21.5931 40.338 21.5661 39.8016C21.539 39.2653 21.6456 38.7306 21.8761 38.2456C22.0311 37.8609 22.1648 37.46 22.293 37.0592C19.3046 37.0072 16.4061 36.0287 13.9978 34.2589C12.4486 33.1367 11.1365 31.7193 10.1372 30.0883C9.13783 28.4572 8.47089 26.6447 8.17471 24.755C7.87853 22.8653 7.95897 20.9357 8.4114 19.0771C8.86383 17.2186 9.67931 15.4679 10.8109 13.9257C11.9426 12.3834 13.368 11.0801 15.0053 10.0908C16.6425 9.1014 18.4592 8.44548 20.3509 8.16074C22.2426 7.876 24.172 7.96806 26.028 8.43163C27.884 8.8952 29.63 9.72112 31.1656 10.8619L33.5547 12.6469C34.7787 11.8593 36.0659 11.1747 37.403 10.6C38.9646 9.94734 40.5725 9.41145 42.2135 8.99676Z" fill="white"/>
    <path d="M118.122 15.0658C116.857 15.0844 115.612 15.3893 114.482 15.9576C113.352 16.5259 112.365 17.3429 111.596 18.3472V5.38745H107.64V38.0139H111.596V24.3006C111.552 22.8109 112.1 21.3645 113.12 20.2772C114.139 19.1898 115.547 18.5498 117.037 18.4968C120.944 18.4968 121.762 21.9331 121.762 24.819V38.0139H125.717V24.4182C125.717 17.5135 121.623 15.0658 118.122 15.0658Z" fill="white"/>
    <path d="M102.204 11.1066C100.447 10.2844 98.5268 9.87148 96.587 9.89877C89.6387 9.89877 85.2719 14.7727 85.2719 22.6233C85.2719 30.3885 89.4462 35.0219 96.3946 35.0219C98.4517 35.0336 100.489 34.6225 102.381 33.8141L102.723 33.6485L103.594 36.9512L103.359 37.0634C101.975 37.7368 99.2114 38.4582 95.6196 38.4582C86.8272 38.4582 81.1403 32.291 81.1403 22.7409C81.1403 12.8755 87.1693 6.46244 96.5015 6.46244C100.254 6.46244 102.408 7.26407 103.359 7.73971L103.605 7.85728L102.536 11.2722L102.204 11.1066Z" fill="white"/>
    <path d="M72.2411 9.73915C72.2157 10.5286 72.5017 11.2964 73.0375 11.8768C73.2745 12.1527 73.5692 12.3733 73.9007 12.5229C74.2323 12.6725 74.5926 12.7475 74.9564 12.7426C75.3219 12.7441 75.6835 12.6677 76.0172 12.5184C76.3508 12.3691 76.6488 12.1504 76.8912 11.8768C77.4329 11.2994 77.7232 10.5305 77.6983 9.73915C77.7174 8.94878 77.4279 8.18203 76.8912 7.60147C76.6502 7.32543 76.3528 7.10434 76.019 6.95314C75.6852 6.80193 75.3228 6.72414 74.9564 6.72502C74.5919 6.72218 74.2313 6.79918 73.8998 6.95059C73.5683 7.10201 73.274 7.32417 73.0375 7.60147C72.5062 8.18459 72.2208 8.95053 72.2411 9.73915Z" fill="white"/>
    <path d="M188.584 9.73912C188.561 10.5281 188.847 11.2949 189.38 11.8768C189.617 12.1524 189.912 12.3728 190.243 12.5223C190.575 12.6719 190.935 12.7471 191.299 12.7426C191.664 12.7448 192.026 12.6686 192.36 12.5193C192.694 12.37 192.992 12.1509 193.234 11.8768C193.775 11.2993 194.066 10.5305 194.041 9.73912C194.06 8.94875 193.77 8.182 193.234 7.60143C192.993 7.32513 192.695 7.10385 192.362 6.95262C192.028 6.80139 191.665 6.72376 191.299 6.72498C190.934 6.72287 190.574 6.8002 190.243 6.95156C189.911 7.10293 189.617 7.3247 189.38 7.60143C188.851 8.18601 188.566 8.95097 188.584 9.73912Z" fill="white"/>
    <path d="M77.025 31.0494C76.3363 32.0031 75.5958 32.9183 74.8069 33.7909C73.7807 34.8598 72.9576 35.3942 72.3375 35.3942C71.9688 35.3942 71.7443 35.2873 71.6481 35.0682C71.5352 34.8485 71.4783 34.6044 71.4824 34.3574C71.568 33.7277 71.6966 33.1045 71.8672 32.4923L72.2681 31.215L74.6733 22.6376L74.7909 22.1512C74.994 21.3817 75.1276 20.8045 75.2024 20.409C75.283 19.9522 75.3205 19.4888 75.3147 19.0249C75.3308 18.1815 75.0551 17.3584 74.5343 16.6948C74.3084 16.3871 74.013 16.1371 73.6721 15.9652C73.3312 15.7934 72.9545 15.7046 72.5727 15.7061C71.2365 15.7061 69.8629 16.3688 68.4572 17.6835C67.8451 18.2752 67.2754 18.9092 66.7521 19.5807L67.5325 20.2915L67.7089 20.0884C68.728 19.0195 69.5493 18.4851 70.1729 18.4851C70.5417 18.4851 70.7662 18.5973 70.857 18.8165C70.9741 19.0348 71.033 19.2796 71.028 19.5272C70.9424 20.157 70.8138 20.7801 70.6432 21.3924L67.8478 31.2631L67.7142 31.7441C67.5111 32.5137 67.3775 33.0909 67.3027 33.481C67.2226 33.9397 67.185 34.4048 67.1904 34.8705C67.1743 35.7139 67.4499 36.537 67.9708 37.2006C68.196 37.5089 68.4913 37.7594 68.8324 37.9313C69.1734 38.1032 69.5504 38.1916 69.9324 38.1892C71.2686 38.1892 72.6422 37.5266 74.0533 36.2119C75.3143 34.9309 76.4558 33.5374 77.4633 32.0487L77.7412 31.6693L77.025 31.0494Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M129.966 27.1412C129.966 20.0334 134.092 15.0686 140.004 15.0686C146.466 15.0686 148.759 20.6052 148.764 25.3455C148.764 26.0937 148.727 26.6709 148.684 27.2053V27.4993H133.873C134.001 32.3892 136.578 35.1842 140.95 35.1842C142.863 35.2202 144.762 34.8467 146.519 34.0887L146.856 33.9497L147.658 36.9532L147.422 37.0654C145.23 38.0474 142.844 38.5241 140.442 38.4602C134.076 38.4602 129.966 34.0192 129.966 27.1412ZM139.683 18.2217C136.102 18.2217 134.376 21.4656 133.964 24.3836H144.846C144.819 23.2346 144.574 21.0114 143.13 19.5417C142.676 19.098 142.136 18.752 141.543 18.525C140.95 18.298 140.317 18.1948 139.683 18.2217Z" fill="white"/>
    <path d="M176.167 15.0653C173.174 15.0653 170.822 16.4228 169.171 19.1002C168.68 17.902 167.842 16.8778 166.765 16.1589C165.688 15.44 164.421 15.0592 163.126 15.0653C161.836 15.0288 160.559 15.3295 159.421 15.9378C158.283 16.5462 157.323 17.4409 156.637 18.5337L156.466 15.541H152.917L152.949 16.3319C153.024 17.9352 153.088 19.4583 153.088 21.4998V38.0134H156.984V24.3376C156.984 21.131 159.299 18.4589 162.03 18.4589C164.761 18.4589 166.274 20.6287 166.274 24.2574V38.0134H170.186V23.9795C170.186 20.9867 172.388 18.4589 174.997 18.4589C178.701 18.4589 179.476 22.0716 179.476 25.1018V38.0134H183.394V24.562C183.404 15.5249 177.375 15.0653 176.167 15.0653Z" fill="white"/>
    <path d="M189.214 15.5874H193.17V38.017H189.214V15.5874Z" fill="white"/>
    <path d="M205.901 24.8493C202.913 23.5721 202.053 22.7491 202.053 21.1832C202.053 19.4089 203.373 18.2599 205.415 18.2599C206.861 18.2681 208.274 18.695 209.482 19.4891L209.814 19.7029L211.005 16.5498L210.781 16.4108C209.213 15.5096 207.432 15.0463 205.623 15.0694C201.315 15.0694 198.188 17.8057 198.188 21.584C198.188 24.331 200.123 26.5007 203.945 28.0398C206.772 29.1782 207.761 30.231 207.761 32.048C207.761 34.02 206.211 35.2545 203.72 35.2545C202.038 35.1795 200.401 34.6854 198.958 33.8169L198.627 33.6245L197.494 36.7883L197.718 36.9219C199.51 37.9375 201.538 38.4646 203.597 38.4504C208.483 38.4504 211.615 35.7783 211.615 31.6899C211.652 28.5475 209.931 26.5007 205.901 24.8493Z" fill="white"/>
    <path d="M216.981 11.7386L220.893 10.3812V15.5864H226.548V18.8143H220.909V31.106C220.909 33.8476 221.727 35.0714 223.555 35.0714C224.28 35.0954 225.004 34.9923 225.693 34.7668L226.083 34.6279L226.329 37.8344L226.126 37.9252C225.049 38.3224 223.905 38.504 222.759 38.4597C217.002 38.4597 217.002 33.0567 217.002 31.0205V18.8197H213.64V15.5864H216.981V11.7386Z" fill="white"/>
    <path d="M240.22 15.1756C239.995 15.1341 239.766 15.1109 239.536 15.1061C237.147 15.1061 234.977 16.5811 233.769 18.9433L233.646 15.5871H230.081V15.9238C230.188 17.8103 230.236 19.8785 230.236 22.4223V38.0168H234.191V25.864C234.191 22.5292 235.795 18.9165 239.317 18.9165C239.707 18.9188 240.096 18.9437 240.482 18.9914L240.835 19.0341V15.2611L240.552 15.229L240.22 15.1756Z" fill="white"/>
    <path d="M254.871 28.9479L259.345 15.5874H263.556L257.591 31.4543C254.978 38.5728 253.139 42.6665 250.355 45.2905C249.062 46.5249 247.491 47.4288 245.774 47.9252L245.485 48L244.379 44.6278L244.668 44.5209C249.515 42.7306 251.461 37.4131 251.498 36.9749C251.472 36.6988 251.398 36.4294 251.279 36.1786L243.395 15.5874H247.719L252.557 29.012C252.984 30.1771 253.364 31.4543 253.716 32.7156C253.885 32.1432 254.072 31.5368 254.269 30.8992L254.274 30.885C254.388 30.5155 254.506 30.1342 254.625 29.7442L254.871 28.9479Z" fill="white"/>
    <path d="M50.9203 10.0709C48.7911 13.9883 48.3313 18.4177 47.8732 22.8302C47.8208 23.3354 47.7682 23.8413 47.7133 24.3453C47.4883 26.7202 47.0519 29.0703 46.4092 31.3676C46.2631 31.8557 46.0974 32.342 45.9121 32.8266C45.3558 34.2885 44.6023 35.6676 43.6726 36.9256C42.7996 38.1029 41.7441 39.1331 40.5458 39.9771C38.6281 41.2883 36.5284 42.3111 34.3137 43.0127C30.2575 44.4079 25.9819 45.0561 21.6944 44.9259C19.9368 44.9495 18.182 44.7793 16.4617 44.4182C16.0499 44.3604 15.6607 44.1944 15.334 43.9372C15.241 43.8549 15.1797 43.7427 15.1604 43.6201C15.1412 43.4975 15.1652 43.3719 15.2285 43.2651C15.2917 43.1582 15.3902 43.0767 15.5069 43.0346C15.6237 42.9924 15.7515 42.9922 15.8685 43.034C19.6099 44.1029 23.9873 43.4455 27.5631 41.9331C31.6786 40.1856 34.1213 36.8187 35.6125 32.7037C36.008 31.6348 36.3554 30.4912 36.6815 29.3582C36.6839 29.3351 36.6839 29.3118 36.6815 29.2887C36.6802 29.2369 36.6614 29.187 36.6281 29.1472C36.5949 29.1074 36.5491 29.08 36.4984 29.0695C36.4476 29.059 36.3947 29.0659 36.3484 29.0892C36.3021 29.1125 36.265 29.1508 36.2432 29.1979C35.6392 30.5339 35.0299 31.87 34.3671 33.1312C31.1602 39.3252 23.6185 42.024 23.6185 39.7046C23.6253 39.475 23.6799 39.2492 23.7789 39.0419C24.7684 36.6403 25.1014 33.9357 25.4343 31.2314C25.5978 29.9038 25.7613 28.5762 26.0024 27.2846C26.2356 25.916 26.6137 24.576 27.1301 23.2872C29.2414 18.2583 33.3356 14.667 38.2636 12.5293C40.538 11.5376 43.066 10.9263 45.5693 10.3209C47.1133 9.94747 48.6479 9.57634 50.1078 9.11966C50.208 9.08228 50.3156 9.06927 50.4218 9.08172C50.528 9.09417 50.6297 9.13171 50.7184 9.19125C50.8072 9.25078 50.8806 9.33059 50.9324 9.42409C50.9842 9.51758 51.013 9.62206 51.0165 9.7289C51.0141 9.84925 50.981 9.967 50.9203 10.0709Z" fill="white"/>
    </svg>
</template>
<script>
export default {
    name: "Logo",
};
</script>