<template>
    <span class="oa-company-brand">{{customerName}}</span>
</template>
<style>
    .oa-company-brand {
        color: var(--color-brand-secondary);
        font-size: 18px;
        font-weight: 700;
    }
</style>
<script>

export default {
    name: "CompanyBrand",
    props: ["customerName"]
};
</script>