<template>
  <div class="oa-not-found">
    <main class="oa-not-found__main">
      <img
        class="oa-not-found__logo"
        src="https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/openaccess/logo-ichemistry.svg"
        alt="iChemistry logo"
      />
      <img
        class="oa-not-found__404"
        src="https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/openaccess/not-found.svg"
        alt="404 logo"
      />
      <h4 class="oa-not-found__title">{{currentLanguage.pageIsNotAvailable}}</h4>
      <p class="oa-not-found__text">
        {{currentLanguage.invalidQrCodeOrUrl}}
      </p>
    </main>
  </div>
</template>

<script>
import useTranslations from "@modules/translations.js";

export default {
  name: "NotFound",
  setup() {
    const { currentLanguage, changeLanguage } = useTranslations();
    let browserLang = navigator.languages.at(0);
    changeLanguage(browserLang);
    return { currentLanguage };
  },
};
</script>


<style>
.oa-not-found {
  background-color: var(--color-gray-100);
  min-height: 100vh;
  padding-top: 100px;
}

.oa-not-found__main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.oa-not-found__logo {
  padding-bottom: 160px;
}

.oa-not-found__404 {
  margin-bottom: 33px;
}

.oa-not-found__title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
}

.oa-not-found__text {
  width: 230px;
  text-align: center;
  color: var(--color-gray-700);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
</style>