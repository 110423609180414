<template>
    <Logo />
</template>
<script>
import Logo from "@components/common/Logo.vue"

export default {
    name: "iChemistryBrand",
    components: {
        Logo,
    }
};
</script>