<template>
  <div class="oa-department-path" :data-path="path">
    <span class="oa-department-path__icon" :class="{'oa-department-path__icon--show': departmentLocation}" @click="showDepartmentLocation()" />
    <div class="oa-department-path__location">
      <h3 class="oa-department-path__headline">
        {{ currentLanguage.departmentLocation }}
      </h3>
      <p class="oa-department-path__route" v-if="!!path">
        <span
          class="oa-department-path__route__item"
          v-for="(item, index) in path.split('\\')"
          :key="index"
        >
          {{ item }}
        </span>
      </p>
    </div>
  </div>
</template>
<style>
.oa-department-path {
  margin-left: 4px;
  text-align: left;
}

.oa-department-path__icon {
  display: block;
  position: relative;
  width: 14px;
  height: 14px;
  background-image: url("https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/openaccess/exclamation-circle.svg");
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}

.oa-department-path__icon:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 10px;
  translate: -50% 20px;
  left: 50%;
  background-image: url("https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/openaccess/arrow-gray-up.svg");
  background-repeat: no-repeat;
  opacity: 0;
  transition: 0.3s opacity ease-out;
}

.oa-department-path__location {
  position: absolute;
  left: 1rem;
  translate: 0% 14px;
  z-index: 10;
  background-color: var(--color-gray-900);
  min-width: 340px;
  padding: 16px;
  min-height: 80px;
  border-radius: 8px;
  transition: 0.3s opacity ease-out;
  opacity: 0;
  pointer-events: none;
}

.oa-department-path__headline,
.oa-department-path__route {
  font-size: 14px;
  line-height: 20px;
}

.oa-department-path__headline {
  font-weight: 600;
  margin-bottom: 8px;
}

.oa-department-path__route {
  font-weight: 400;
}

.oa-department-path__route__item {
  display: inline-flex;
  align-items: center;
  padding-right: 20px;
  background-image: url("https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/openaccess/path-arrow-right.svg");
  background-position: right 7px center;
  background-repeat: no-repeat;
  background-size: 7px;
}

.oa-department-path__route__item:last-child,
.oa-department-path__route__item:only-child {
  color: var(--color-brand-secondary);
  background-image: none;
}

@media screen and (max-width: 640px) {
  .oa-department-path__location {
    width: calc(100% - 32px);
  }
}

@media screen and (min-width: 769px) {
  .oa-department-path__location,
  .oa-department-path__icon:before {
    transition-delay: 0.25s;
  }

  .oa-department-path:has(.oa-department-path__location:hover) .oa-department-path__icon:before {
    opacity: 1;
  }

  .oa-department-path__location:active {
    pointer-events: all;
    opacity: 1;
  }

  .oa-department-path__icon:active + .oa-department-path__location {
    transition: 0.15s opacity ease-out;
    pointer-events: all;
    opacity: 1;
  }

  .oa-department-path__icon:active:before {
    opacity: 1;
    transition: 0.15s opacity ease-out;
  }
}

@media screen and (max-width: 769px) {
  .oa-department-path__icon--show:before {
    opacity: 1;
    transition: 0.15s opacity ease-out;
  }

  .oa-department-path__icon--show + .oa-department-path__location {
    transition: 0.15s opacity ease-out;
    pointer-events: all;
    opacity: 1;
  }
}
</style>

<script>
import useTranslations from "@modules/translations.js";

export default {
  name: "DepartmentPath",
  data() {
    return {
      departmentLocation: false
    }
  },
  setup() {
    const { currentLanguage, changeLanguage } = useTranslations();
    return { currentLanguage };
  },
  props: ["path"],
  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
  },
  methods: {
    showDepartmentLocation() {
      if(window.matchMedia("(max-width: 768px)").matches) {
        return this.departmentLocation = !this.departmentLocation;
      }
    },
    handleOutsideClick(event) {
      if (!this.$el.contains(event.target)) {
        this.departmentLocation = false;
      }
    }
  }
};
</script>
