import axiosLib from 'axios';
const instance = axiosLib.create({
    baseURL: process.env.VUE_APP_URL,
});

const defaultOptions = () => {
    return {
        headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache,no-store,must-revalidate,max-age=-1,private',
        },
        withCredentials: true,
    }
};
const axios = {
    get: (url, options) => instance.get(url + `${url.indexOf('?') >= 0 ? '&' : '?'}_=${Math.random()}`, { ...defaultOptions(), ...(options || {}) })
        .then(response => new Promise(resolve => resolve({ ...response, data: response.data })))
    ,
    post: (url, data, options) => {
        return instance.post(url, data, { ...defaultOptions(), ...(options || {}), })
            .then(response => new Promise(resolve => resolve({ ...response, data: response.data })))
    },
};

export default axios;