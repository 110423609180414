<template>
    <div class="oa-splash-screen">
        <div class="oa-splash-screen__wrapper">
            <iChemistryBrand />
        </div>
    </div>
</template>
<style>
    .oa-splash-screen {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/openaccess/bg-login.svg");
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: var(--color-brand-dark);
        opacity: 0;
        pointer-events: none;
        transition: 0.3s opacity ease-out;
    }

    .oa-splash-screen.oa-splash-screen--show {
        opacity: 1;
        pointer-events: all;
        z-index: 100;
        transition: 0.3s opacity ease-out;
    }

    .oa-splash-screen__wrapper {
        display: flex;
        flex-direction: column;
    }

    .oa-splash-screen.oa-splash-screen--landing {
        position: static;
        text-align: left;
    }
</style>
<script>
import iChemistryBrand from "@components/common/iChemistryBrand.vue";

export default {
    name: "SplashScreen",
    components: {
        iChemistryBrand
    }
};
</script>
