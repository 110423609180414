<template>
	<div class="oa-wrapper">
		<div class="oa-modal">
			<div class="oa-modal__headline">
				<p class="oa-modal__name">Activity based safety sheet</p>
				<span class="oa-modal__close" @click="$emit('closeActivitiesModal')" />
			</div>
			<ul class="oa-modal__list">
                <slot />
			</ul>
		</div>
	</div>
</template>
<script>

export default {
    name: "ModalElement"
}
</script>

