<template>
  <button class="oa-button" :class="{ 'oa-button--active': active }">
    <slot />
  </button>
</template>
<style>
.oa-button {
  position: relative;
  font-family: "Inter";
  flex: 1;
  padding: 10px;
  border-radius: 8px;
  border: none;
  background: transparent;
  color: var(--color-gray-500);
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: color 0.25s linear;
}

.oa-button.oa-button--active {
  color: var(--color-white);
  font-weight: 600;
  transition: color 0.25s linear;
}

.oa-button__type,
.oa-button__number {
  position: relative;
}

.oa-button__number {
  color: var(--color-gray-400);
  font-weight: 500;
  margin-left: 8px;
}

.oa-button--active .oa-button__number {
  color: var(--color-brand-secondary);
}

.oa-button.oa-button--primary {
  background-color: var(--color-brand-primary);
  color: var(--color-white);
}
</style>
<script>
export default {
  name: "Button",
  props: {
    active: Boolean,
  },
};
</script>
