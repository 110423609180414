<template>
  <router-view ref="view" />
</template>
<style>
#app {
  background-image: url("https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/openaccess/bg-login.svg");
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--color-brand-dark);
  display: flex;
  flex-direction: column;
  height: 100dvh;
}
</style>
<script>
import MainPage from "@pages/MainPage.vue";
import NotFound from "@components/NotFound.vue";
import NotFoundPage from "@pages/NotFoundPage";
import router from "./main";

export default {
  name: "App",
  components: {
    MainPage,
    NotFound,
    NotFoundPage,
    router,
  },
  data() {
    return {
      token: null,
    };
  },
  beforeMount() {
    this.token = this.$route.params.token;
    if (!this.token) router.push("unavailable");
  },
};
</script>

