<template>
    <div class="oa-confirm-box">
        <h4 class="oa-confirm-box__caption">{{currentLanguage.missingSDS}}</h4>
        <p class="oa-confirm-box__paragraph">{{currentLanguage.infoSDS}}</p>
        <Button class="oa-button--primary" @click="$emit('continue')">{{currentLanguage.continue}}</Button>
    </div>
</template>
<style>
    .oa-confirm-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        max-width: 310px;
        height: fit-content;
        background-color: var(--color-white);
        border-radius: 24px;
        padding: 24px;
        text-align: center;
    }

    .oa-confirm-box .oa-confirm-box__caption {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        margin-top: 0;
        margin-bottom: 16px;
        color: var(--color-gray-700);
    }

    .oa-confirm-box .oa-confirm-box__paragraph {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: var(--color-gray-500);
    }

    .oa-confirm-box .oa-button {
        width: 100%;
        margin-top: 40px;
        padding: 8px;
        border-radius: 12px;
        min-height: 48px;
    }
</style>
<script>
import useTranslations from "@modules/translations.js";

import Button from "@components/common/Button.vue";

export default {
    name: "ConfirmBox",
    components: {
        Button
    },
    setup() {
        const { currentLanguage, changeLanguage } = useTranslations();
        return { currentLanguage };
    },
};
</script>